@charset "UTF-8";

@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=11fece8a-fc24-4a53-a819-484506273326");
@font-face{
  font-family:"Futura W01_n3";
  src:url("../fonts/2b40e918-d269-4fd9-a572-19f5fec7cd7f.eot?#iefix") format("eot")
}
@font-face{
  font-family:"Futura W01";
  src:url("../fonts/2b40e918-d269-4fd9-a572-19f5fec7cd7f.eot?#iefix");
  src:url("../fonts/2b40e918-d269-4fd9-a572-19f5fec7cd7f.eot?#iefix") format("eot"),url("../fonts/26091050-06ef-4fd5-b199-21b27c0ed85e.woff2") format("woff2"),url("../fonts/cca525a8-ad89-43ae-aced-bcb49fb271dc.woff") format("woff"),url("../fonts/88cc7a39-1834-4468-936a-f3c25b5d81a1.ttf") format("truetype"),url("../fonts/14d6bf5d-15f1-4794-b18e-c03fb9a5187e.svg#14d6bf5d-15f1-4794-b18e-c03fb9a5187e") format("svg");
  font-weight: 300;
  font-style: normal;
}
@font-face{
  font-family:"Futura W01_o3";
  src:url("../fonts/65d2d2f2-a130-47d4-b7db-1d4a0cd63b4b.eot?#iefix") format("eot")
}
@font-face{
  font-family:"Futura W01";
  src:url("../fonts/65d2d2f2-a130-47d4-b7db-1d4a0cd63b4b.eot?#iefix");
  src:url("../fonts/65d2d2f2-a130-47d4-b7db-1d4a0cd63b4b.eot?#iefix") format("eot"),url("../fonts/f6be6d1e-a972-4744-8e7b-5379116f644d.woff2") format("woff2"),url("../fonts/8444512b-291a-403f-99eb-5ec8a1e3c7e2.woff") format("woff"),url("../fonts/c151aaa9-4ac5-4588-bb55-4d49e5b4186b.ttf") format("truetype"),url("../fonts/415b9e60-459a-4d4d-8097-fe693379e60e.svg#415b9e60-459a-4d4d-8097-fe693379e60e") format("svg");
  font-weight: 300;
  font-style: oblique;
}
@font-face{
  font-family:"Futura W01_n4";
  src:url("../fonts/cf053eae-ba1f-44f3-940c-a34b68ccbbdf.eot?#iefix") format("eot")
}
@font-face{
  font-family:"Futura W01";
  src:url("../fonts/cf053eae-ba1f-44f3-940c-a34b68ccbbdf.eot?#iefix");
  src:url("../fonts/cf053eae-ba1f-44f3-940c-a34b68ccbbdf.eot?#iefix") format("eot"),url("../fonts/8bf38806-3423-4080-b38f-d08542f7e4ac.woff2") format("woff2"),url("../fonts/e2b9cbeb-fa8e-41cd-8a6a-46044b29ba52.woff") format("woff"),url("../fonts/c2a69697-4f06-4764-abd4-625031a84e31.ttf") format("truetype"),url("../fonts/dc423cc1-bf86-415c-bc7d-ad7dde416a34.svg#dc423cc1-bf86-415c-bc7d-ad7dde416a34") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face{
  font-family:"Futura W01_o4";
  src:url("../fonts/834e05b5-87ff-453f-9d74-a93eb930e1ec.eot?#iefix") format("eot")
}
@font-face{
  font-family:"Futura W01";
  src:url("../fonts/834e05b5-87ff-453f-9d74-a93eb930e1ec.eot?#iefix");
  src:url("../fonts/834e05b5-87ff-453f-9d74-a93eb930e1ec.eot?#iefix") format("eot"),url("../fonts/159c40d5-d5b5-4d06-b7ba-7762c03ce985.woff2") format("woff2"),url("../fonts/03937062-17d3-443d-809c-d51e0397aaa3.woff") format("woff"),url("../fonts/19124b27-09ee-4d4c-b213-ec1c114d2945.ttf") format("truetype"),url("../fonts/d47d028d-8ba8-46fb-b997-ec2e42ecdcc8.svg#d47d028d-8ba8-46fb-b997-ec2e42ecdcc8") format("svg");
  font-weight: 400;
  font-style: oblique;
}
@font-face{
  font-family:"Futura W01_n7";
  src:url("../fonts/87f56fc1-ab05-4355-90af-9e83740663af.eot?#iefix") format("eot")
}
@font-face{
  font-family:"Futura W01";
  src:url("../fonts/87f56fc1-ab05-4355-90af-9e83740663af.eot?#iefix");
  src:url("../fonts/87f56fc1-ab05-4355-90af-9e83740663af.eot?#iefix") format("eot"),url("../fonts/3cf9e1e8-63a2-497f-86ed-6b63d6de1986.woff2") format("woff2"),url("../fonts/184895ac-545b-450b-9d3a-c0a0a7620977.woff") format("woff"),url("../fonts/bf8628d2-1ae0-4645-ba3f-3ce62d8d907c.ttf") format("truetype"),url("../fonts/20073de8-56dd-4e4c-9434-5097c3f2fd28.svg#20073de8-56dd-4e4c-9434-5097c3f2fd28") format("svg");
  font-weight: 700;
  font-style: normal;
}
@font-face{
  font-family:"Futura W01_o7";
  src:url("../fonts/dc781dc0-5e81-4b8d-a608-e2bac19a9472.eot?#iefix") format("eot")
}
@font-face{
  font-family:"Futura W01";
  src:url("../fonts/dc781dc0-5e81-4b8d-a608-e2bac19a9472.eot?#iefix");
  src:url("../fonts/dc781dc0-5e81-4b8d-a608-e2bac19a9472.eot?#iefix") format("eot"),url("../fonts/0d6f913b-0b53-4085-8bc2-7b98d4ee9429.woff2") format("woff2"),url("../fonts/723c2eab-ca44-4b97-95be-f709b1910ed2.woff") format("woff"),url("../fonts/4043d778-6aa8-4da3-88fb-f25e99332d90.ttf") format("truetype"),url("../fonts/8741fba4-5847-45da-858a-a70d6208f327.svg#8741fba4-5847-45da-858a-a70d6208f327") format("svg");
  font-weight: 700;
  font-style: oblique;
}


@font-face {
  font-family: "mmtp";
  src:url("../fonts/mmtp.eot");
  src:url("../fonts/mmtp.eot?#iefix") format("embedded-opentype"),
    url("../fonts/mmtp.woff") format("woff"),
    url("../fonts/mmtp.ttf") format("truetype"),
    url("../fonts/mmtp.svg#mmtp") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "mmtp" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "mmtp" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-facebook:before {
  content: "\61";
}
.icon-instagram:before {
  content: "\62";
}
.icon-close:before {
  content: "\63";
}
.icon-angle-down:before {
  content: "\64";
}
.icon-youtube:before {
  content: "\65";
}
.icon-angle-left:before {
  content: "\66";
}
.icon-angle-right:before {
  content: "\67";
}
.icon-angle-up:before {
  content: "\68";
}
.icon-twitter:before {
  content: "\69";
}
.icon-youtube-1:before {
  content: "\6a";
}
.icon-exclamation-circle:before {
  content: "\6b";
}
