@import "fonts";
@import "variables";
@import "bootstrap";
@import "lightgallery";


/**
 *	1. Misc
 *	2. Helpers + Bootstrap overrides
 *	3. Typography
 *	4. Header / Navigation
 *	5. Footer
 *  6. Containers / Sections
 *  7. Reusable Components
 *	8. Specifics
 *  9. Partners Page
 *  10. Gallery Page 
 *  11 . Ticket Page
 **/

/* ==========================================================================
1. Misc
========================================================================== */

.site-wrap {
	position: relative;
	// max-width: $container-max;
	margin: 0 auto;
	// padding-top: $nav-height-xs;
	@media (min-width: $screen-sm) {
		// padding-top: 99px;
	}
	&.landing {
		padding-top: 0;
		background-image: url(../images/backgrounds/bg-landing-xs.jpg);
		background-size: 100%;
		background-position: center $grid-gutter-width;
		background-repeat: no-repeat;
		@media (min-width: $screen-sm) {
			// background-image: none;
			background-color: #081731;
			background-image: url(../images/backgrounds/bg-landing.gif);
			background-repeat: repeat-y;
		}
	}
	&.no-primary-menu {
		padding-top: 48px;
	}
}

.content-max {
	max-width: $content-max;
}

.container--custom {
	@media (min-width: $screen-sm) {
		padding-left: 30px;
		padding-right: 30px;
	}
	@media (min-width: $screen-lg) {
		width: 100%;
		max-width: $content-max;
	}
	ul {
		padding-left: 60px;
		margin-bottom: 20px;
		li {
			margin-bottom: 10px;
			padding-left: 10px;
		}
	}
	a:not(.btn, .p-s__link) {
		text-decoration: underline;
	}
}

.tickets-page .mmtp-content-logo {
	padding: 15% 0 0 0;
	margin-left: 6vw;
	// @media (min-width: $screen-md) {
	// 	margin-left: 5%;
	// 
	@media (min-width: $screen-md) {
		margin-left: 6vw;
	}
	@media (min-width: $screen-lg) {
		margin-left: 6vw;
	}
	@media (min-width: $screen-xl) {
		margin-left: 0;
	}
}
.mmtp-content-logo {
	padding: 24% 0 0 0;

	@media (min-width: $screen-md) {
		padding: 15% 0 0 0;
	
	}
}

.social-sidebar {
	display: none;
	@media (min-width: $screen-sm) {
		display: block;
		position: fixed;
		z-index: 10;
		right: 0;
		top: 220px;
		background-color: $brand-primary;
	}
	.social-sidebar__link {
		transition: all 0.3s ease-in-out;
		color: #fff;
		display: block;
		padding: 7.5px 15px;
		&:first-child {
			padding-top: 15px;
		}			
		&:last-child {
			padding-bottom: 15px;
		}			
		&:hover {
			background-color: #fff;
			color: $brand-primary;
			text-decoration: none;
		}
	}
}

.cookies {
	a {
		text-decoration: underline;
		&:hover {
			opacity: 0.8;
		}
	}
	
}

img {
	max-width: 100%;
	&.center {
		display: block;
		margin: 0 auto;
	}
}

iframe {
	border: 0px;
}


/* ==========================================================================
2. Helpers + Bootstrap overrides
========================================================================== */

// General padding
.g-padding-top {
	padding-top: $grid-gutter-width;
}

.g-padding-bottom {
	padding-bottom: $grid-gutter-width;
}

.g-padding-t-b {
	padding-bottom: $grid-gutter-width;
	padding-top: $grid-gutter-width;
}

// Buttons
.btn {
	text-transform: uppercase;
	border-radius: 0;
	letter-spacing: 1px;
	font-size: $font-size-base;
	transition: all 0.3s ease-in-out;
	padding: 15px 30px;
	@media (min-width: $screen-xs) {
		font-size: $font-size-large;
	}	
	@media (min-width: $screen-sm) {
		font-size: $font-size-large / 1.2;
	}	
	@media (min-width: $screen-md) {
		font-size: $font-size-large;
	}
	&.btn-white {
		background-color: #fff;
		color: $brand-primary;
		&:hover {
			background-color: $brand-primary;
			color: white;
		}

		&--no-hover {
			&:hover {
				background-color: #fff;
				color: $brand-primary;
				cursor: default;
			}
		}
	}
	&.btn-blue {
		background-color: $brand-primary-dark;
		color: #fff;
		&:hover {
			background-color: #fff;
			color: $brand-primary-dark;
		}
		&.light-hover {
			&:hover {
				background-color: $brand-primary-dark;
				color: #fff;
				opacity: 0.8;
			}
		}
	}
	&.btn-clear {
		background-color: transparent;
		color: #fff;
	}
	&.btn-submit {
		background-color: #fff;
		color: $brand-primary;
		padding: 12px 45px;
		margin: $grid-gutter-width auto 0 auto;
		display: block;
	}
}

.video-wrapper {
	position: relative;
	padding-bottom: 53%;
	padding-top: 25px;
	height: 0;
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.yt-video-overlay {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		z-index: 10;
		cursor: pointer;
		background-position: center;
		background-size: cover;
	}
}

// Modal
.modal {
	.modal-dialog {
		// margin: 0 auto;
		margin-top: 50px;
		.modal-content {
			box-shadow: none;
			.modal-header {
				.modal-title {
					color: #fff;
					text-align: center;
				}
				h4 {
					font-size: $font-size-h4;
					@media (min-width: $screen-sm) {
						font-size: $font-size-h3;
					}
				}				
				h5 {
					font-size: $font-size-h4;
				}
			}
			.modal-footer {
				text-align: center;
				line-height: 1;
				.btn {
					font-size: $font-size-base;
					.icon {
						font-size: $font-size-large;
					}
				}
			}
		}
	}
	p {
		color: #fff;
		text-align: center;
	}
	a {
		color: #fff;
		text-decoration: underline;
		&:hover {
			color: #fff;
		}
	}
	&.in {
		.modal-dialog {
			@media (min-width: $screen-sm) {
				// top: 50%;
				// transform: translate(0,-50%);			
			}
		}
	}
}

// Forms
.form-group, .mc-field-group {
	.form-control, input {
		height: 50px;
		letter-spacing: 1px;
		text-align: center;
		font-size: $font-size-large;
		text-transform: uppercase;
		&:focus {
			border: 3px solid #fff;
		}
	}
}

#mc_embed_signup div.mce_inline_error {
	background-color: $brand-danger !important;
	text-align: left; 
}

#mc_embed_signup input.mce_inline_error {
	border-color: $brand-danger !important;
}

#mce-responses {
	color: white;
}

// MailChimp for wordpress
.mc4wp-form-fields {
	margin-bottom: 20px;
}

.mc4wp-error {
	background-color: $brand-danger;
	p {
		padding: 5px;
	}
}

.lt-ie10 {
	.form-group {
		.ie9 {
			position: relative;
			clip: auto;
			width: auto;
			height: auto;
			color: #fff;
			overflow: visible;
		}
	}
}

.text-shadow {
	text-shadow: 3px 3px 5px #000;
}

/* ==========================================================================
3. Typography
========================================================================== */

h1, h2, h3, h4, h5 {
	text-transform: uppercase;
	letter-spacing: 1px;
}

h1 {
	&:first-child {
		margin-top: 0;
	}
}

p {
	letter-spacing: 0.5px;
	line-height: 1.5;
	margin-bottom: 20px;
}

.text-large {
	font-size: $font-size-large !important;
}

.text-small {
	font-size: $font-size-small !important;  
}


.language-dropdown, .location-dropdown {
	font-size: 1.5rem; 
	background: none;
	border: none; 

	.toggle-toggle {
		background: none;
		border: none;

		.dropdown-arrow {
			transform: rotate(90deg);

			&:before {
				font-size: 1rem;
				position: relative;
				top: -15px;
			}
		} 
	}


}

.location-menu {
	top: 44px;
	background: #002450;
	border-radius: 0;
	border: none;
	padding: 10px 19px;
	min-width: 130px;

	a {
		color: $component-active-color;
	}
}


.language-menu {
	top: 44px;
	left: 172px;
	background: #002450;
	border-radius: 0;
	border: none;
	padding: 10px 19px;
	min-width: 120px;

	@media (min-width: $screen-md) {
		left: 240px;
	}


	@media (min-width: $screen-lg) {
		left: 185px;
	}

	@media (min-width: $screen-xl) {
		left: 200px;
	}

	a {
		color: $component-active-color;
	}
}

.flag-icon {
	display: inline-block;
	width: 35px;
	margin-right: 16px;
}


/* ==========================================================================
4. Header/ Navigation
========================================================================== */

// Main header
.m-h {
	font-size: 0; // IE9 support
	position: relative;
	z-index: 100;
	width: 100%;
	max-width: $container-max;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	overflow: visible;
	// Pre nav
	.m-h__pre-nav {
		color: #fff;
		background-color: $brand-primary;
		width: 100%;
		display: flex;
		align-items: stretch;
		flex-wrap: wrap;
		padding-bottom: 2px;
		@media (min-width:$nav-breakpoint) {
			padding-top: 2px;
		}
		.m-h__nav-toggle {
			padding: 15px;
			background-color: $brand-primary-dark;
			border: 0;
			float: right;
			@media (min-width:$nav-breakpoint) {
				display: none;
			}
			.m-h__nav-bar {
				height: 3px;
				background-color: #fff;
				width: 18px;
				float: left;
				clear: both;
				margin: 2px 0;
			}
		}
		.m-h__lang {
			display: inline-block; // IE9 support
			float: left; // IE9 support
			// width: 70%; // IE9 support
			flex-grow: 2;
			padding: 13px 10px 5px 10px;
			@media (min-width: $screen-sm) {
				// width: 30%;
			}
			.m-h__lang-link {
				color: $brand-primary-extra-light;
				padding: 0 8px;
				line-height: 1;
				position: relative;
				font-size: $font-size-medium;
				letter-spacing: 1px;
				// font-weight: $light;
				&:after {
					content: "";
					height: 65%;
					width: 1px;
					position: absolute;
					right: 0;
					background-color: #fff;
					top: 50%;
					transform: translateY(-50%);
				}
				&.first {
					padding: 0 9px 0 0;
				}				
				&:last-child {
					&:after {
						display: none;
					}
				}
				&.active {	
					color: #fff;	
					text-decoration: none;
				}
			}
			.m-h__loc {
				padding-right: 10%;
				@media (min-width: $screen-sm) {
					padding-right: 5%;
				}				
				@media (min-width: $screen-md) {
					padding-right: 10%;
				}
				.m-h__loc-link {
					float: left;
					margin-right: 5px;
					opacity: 0.6;
					border-color: $brand-primary-dark;
					&.active {
						opacity: 1;
						border: 1px solid #fff;
					}
					img {
						float: left;
					}
				}	
			}
		}
		.m-h__central-links {
			display: inline-block; // IE9 support
			width: 100%;
			padding-left: 2px;
			padding-right: 2px;
			display: flex;
			align-items: stretch;

			@media (min-width:$nav-breakpoint) {
				width: 40%;
			}

			.m-h__central-link {
				width: 50%;
				float: left;
				display: flex;
				text-align: center;
				align-items: center;
				justify-content: center;
				font-weight: $bold;
				padding-top: 6px;
				padding-bottom: 6px;
				&:hover {
					background-color: $brand-primary-dark;
				}
				&:first-child {
					// border-right: 1px solid $brand-primary;
					margin-right: .5%;
				}
				&:last-child {
					float: right;
				}

			}

			&.long-link {

				padding-top: 2px;

				@media (min-width: $screen-lg) {
					padding-top: 0;
				}

				a {
					width: 100%;
					margin-right: 0!important;

					@media (min-width: $screen-lg) {
						width:50%;
					}

				}
			}

			&.empty-one {
				width: 30%!important;;
			}


		}
		.m-h__sign-up {
			display: none;
			width: 30%;
			padding: 10px 10px 5px 10px;
			text-align: right;
			@media (min-width:$nav-breakpoint) {
				display: inline-block; // IE9 support
			}
			.m-h__sign-up-link {
				color: #fff;
				text-transform: uppercase;
				font-size: $font-size-medium;
				letter-spacing: 1px;
				// font-weight: $light;
			}
		}
		.m-h__sub-title {
			text-transform: uppercase;
			display: block;
			font-size: 10px;
			padding-bottom: 3px;
			line-height: 1;
		}
	}
}

.lt-ie10 {
	.m-h {
		.m-h__pre-nav {
			.m-h__central-links {
				.m-h__central-link {
					width: 49.5%;
				}
			}
		}
	}
}

// Main header nav
.m-h__nav {
	display: none;
	background-color: $brand-primary-dark;
	overflow-y: auto;
	@media (min-width: $nav-breakpoint) {
		overflow: auto;
	}
	@media (min-width: $nav-breakpoint) {
		display: block;
		padding-top: 10px;
		padding-bottom: 10px;
	}
	.m-h__nav-close {
		color: #fff;
		position: absolute;
		right: 8px;
		top: 9px;
		font-size: 35px;
		line-height: 1;
		padding: 0;
		background-color: transparent;
		border: 0;
		z-index: 2;
		@media (min-width: $nav-breakpoint) {
			display: none;
		}
	}
	.m-h__list {
		list-style: none;
		padding: 0;
		display: flex;
		flex-wrap: wrap;
		text-align: center;
		margin: 0;
		padding-top: 50px;
		&:last-child {
			padding-top: 0;
		}
		@media (min-width: $nav-breakpoint) {
			padding-top: 0;
			display: block;
		}
		.m-h__list-item {
			width: 100%;
			margin: 10px 0;
			@media (min-width: $nav-breakpoint) {
				width: auto;
				display: inline-block;
				margin: 0;
			}
			.m-h__list-link {
				padding: 10px 50px;
				display: inline-block;
				text-transform: uppercase;
				color: #fff;
				text-align: center;
				font-size: $font-size-medium;
				// font-weight: $light;
				letter-spacing: 1px;
				line-height: 1;
				position: relative;
				@media (min-width: $nav-breakpoint) {
					padding: 6px 15px;
					font-size: $font-size-large;
					border: 1px solid $brand-primary-dark;
				}					
				@media (min-width: $screen-md) {
					padding: 6px 30px;
				}
				&:after {
					@media (min-width: $nav-breakpoint) {
						content: "";
						height: 60%;
						width: 1px;
						position: absolute;
						right: -1px;
						background-color: #fff;
						top: 50%;
						transform: translateY(-50%);
						z-index: 4;
					}
				}
				&.icon {
					padding: 10px 30px;
					&:after {
						content: "";
						height: 60%;
						width: 1px;
						position: absolute;
						right: 0;
						background-color: #fff;
						top: 50%;
						transform: translateY(-50%);
					}
					&:last-child {
						&:after {
							display: none;
						}
					}
				}
			}
			&:last-child, &.hide-pipe {
				.m-h__list-link {
					&:after {
						display: none;
					}
				}
			}
			&.active {
				.m-h__list-link {
					border: 1px solid #fff;
					&:after {
						display: none;
					}
				}
			}
		}
	}
	&.active {
		display: block;
		position: fixed;
		min-height: 100vh;
		top: 0;
		left: 0;
		width: 100%;
		background-color: $brand-primary-extra-dark;
		@media (min-width: $nav-breakpoint) {
			position: relative;
		}
	}
}
.visible-on-toggle {
	display: block!important;
	@media(min-width:$nav-breakpoint){

		display: none !important
	}
}

.blue-phase {

	// background: #00264fba;
	position: absolute;
	width: 100%;
	// max-width: 350px;
	transform: translate(-50%, -50%);
	bottom: 0;
	left: 50%;

	@media (min-width: $screen-sm) {
		max-width: 350px;
	}


	&_text {
		background: $brand-phaser;
		color: white;
		display: inline-block;
		text-align: center;
		padding-top: 15px;
		width: 100%;
		bottom: 0;
		position: absolute;

		.m-h__central-link {
			font-weight: 700;
		}

		p {
			a {
				color: $component-active-color;
			}
		}

	}
}

/* ==========================================================================
5. Footer
========================================================================== */

// Main footer
.m-f {
	width: 100%;
	// max-width: $container-max;
	// Footer nav
	.m-f__nav {
		background-color: $brand-primary;
		padding: $grid-gutter-width / 1.5 0;
		.m-f__list {
			text-align: center;
			padding: 0;
			margin: 0;
			.m-f__list-item {
				display: inline-block;
				.m-f__list-link {
					color: #fff;
					display: block;
					position: relative;
					text-transform: uppercase;
					text-align: center;
					font-size: $font-size-small;
					letter-spacing: 1px;
					line-height: 1;
					padding: 6px 8px;	
					@media (min-width: $screen-xs) {
						padding: 6px 15px;	
					}	
					@media (min-width: $screen-md) {
						padding: 6px 18px;
					}
					&:after {
						@media (min-width: $screen-sm) {
							content: "";
							height: 60%;
							width: 1px;
							position: absolute;
							right: 0;
							background-color: #fff;
							top: 50%;
							transform: translateY(-50%);
						}
					}
				}
				&:nth-child(2), &:nth-child(7n) {
					.m-f__list-link {
						&:after {
							display: none;
							@media (min-width: $screen-sm) {
								display: block;
							}
						}
					}
				}
				&:last-child {
					.m-f__list-link {
						&:after {
							display: none;
						}
					}
				}
			}
		}
	}
	// Footer sponsers
	.m-f__sponsers {
		background-color: $brand-primary-dark;
		padding: $grid-gutter-width / 2 0;
		text-align: center;
		margin: 0;
		.m-f__sponsers-item {
			display: inline-block;
			.m-f__sponsers-link {
				padding: 0 10px;
			}
			p {
				color: white;
				font-size: $font-size-small;
				margin: 0;
				a {
					color: white;
				}
			}
		}
	}

	
	.h-c__watch-trailer {
		position: absolute;
		right: 50%;
		transform: translateX(50%);
		bottom: 190px;
		font-weight: $bold;
		padding: 10px 30px;
		@media (min-width: 321px) {
			bottom: 240px;
		}		
		@media (min-width: $screen-xs) {
			bottom: 180px;
		}		
		@media (min-width: $screen-sm) {
			bottom: 15%;
			padding: 15px 30px;
		}
	}	
	.h-c__links {
		position: relative;
		// right: 50%;
		// transform: translateX(50%);
		// bottom: 70px;
		display: flex;
		// align-items: stretch;
		width: 100%;
		@media (min-width: $screen-sm) {
			flex-wrap: wrap;
			// bottom: $grid-gutter-width;
		}		
		@media (min-width: $screen-md) {
			width: 99%;
		}		
		@media (min-width: $screen-lg) {
			width: 100%;
			padding: 10px 15%;
		}
		.h-c__link {
			display: inline-block; // IE9 support
			width: 32%; // IE9 support
			padding: 5px;
			white-space: normal;
			flex-basis: initial;
			justify-content: center;
			display: flex;
			align-items: center;
			text-align: center;
			flex-grow: 1;
			margin-left: 2px;
			margin-right: 2px;
			font-size: $font-size-small;
			letter-spacing: 0;
			&:first-child {
				margin-left: 4px;
			}
			&:last-child {
				margin-right: 4px;
			}
			@media (min-width: $screen-sm) {
				flex-basis: 0;
				padding: 15px;
				letter-spacing: 1px;
			}
			@media (min-width: $screen-md) {
				font-size: $font-size-medium;
				padding: 15px 10px;
				margin-top: 5px;
			}
		}
	}
}


/* ==========================================================================
6. Containers / Sections
========================================================================== */



/* ==========================================================================
7. Reusable Components
========================================================================== */
.announce {
	margin-top: $grid-gutter-width / 2;
	margin-bottom: 0;
	color: $brand-primary-extra-dark;
	text-align: center;
	border-radius: 0;
	background-color: rgba(255,255,255, 0.8);
	padding: 15px 15px 20px;
	@media (min-width: 815px) {
		width: 70%;
		max-width: 400px;
	}
	&:first-child {
		margin-top: $grid-gutter-width;
	}
	.close {
		opacity: 1;
		top: 0;
		left: 0;
		float: none;
		right: 0;
		&:hover, &:focus {
			color: #fff;
		}
	}
	h4 {	
		font-size: $font-size-large * 1.1;
		position: relative;
		// padding-left: $grid-gutter-width / 1.5;
		font-weight: 500;
		margin-bottom: 5px;
		// &:before {
		// 	content: '\6b';
		// 	font-family: 'mmtp';
		// 	text-transform: none;
		// 	position: absolute;
		// 	left: 0;
		// 	font-size: 16px;
		// 	top: -2px;
		// }
	}
	p {
		font-size: $font-size-small *1.3;
		line-height: 1.1;
		font-weight: 400;
	}
	a {
		color: $brand-primary-extra-dark;
	}
}
// Alert - bootstrap
.cookies {
	margin-top: $grid-gutter-width / 2;
	margin-bottom: 0;
	color: #fff;
	background-color: rgba($brand-primary-dark, 0.7);
	@media (min-width: $screen-sm) {
		width: 60%;
		max-width: 325px;
	}
	&:first-child {
		margin-top: $grid-gutter-width;
	}
	.close {
		opacity: 1;
		top: -5px;
		right: -24px;
		&:hover, &:focus {
			color: #fff;
		}
	}
	h4 {	
		font-size: $font-size-small;
		position: relative;
		padding-left: $grid-gutter-width / 1.5;
		&:before {
			content: '\6b';
			font-family: 'mmtp';
			text-transform: none;
			position: absolute;
			left: 0;
			font-size: 16px;
			top: -2px;
		}
	}
	p {
		font-size: $font-size-small;
		line-height: 1.3;
	}
	a {
		color: #fff;
	}
}

// General content
.g-c {
	background-color: #fff;
	background-image: url(../images/backgrounds/bg-general-sm.jpg);
	background-position: right top;
	background-repeat: no-repeat;
	padding-top: $grid-gutter-width;
	background-size: inherit;
	@media (min-width: $screen-xs) {
		background-image: url(../images/backgrounds/bg-general-md.jpg);
	}
	// @media (min-width: $screen-sm) {
	// 	background-image: url(../images/backgrounds/bg-general-md.jpg);
	// }
	@media (min-width: $screen-md) {
		background-image: url(../images/backgrounds/bg-general-lg.jpg);
	}
	
}
.g-c.tickets-page {
	background-color: #fff;
	background-image: url(../images/backgrounds/bg-ticket.jpg)!important;
		background-position: right top;
		background-repeat: no-repeat;
		padding-top: $grid-gutter-width;
		background-size: contain;

		@media (min-width: $screen-xs) {
		// background-size: 60%;
	}

	@media (min-width: $screen-md) {}
}

// Full width video
.fw-video {
	display: block;
	width: 100%;
}

.custom-video {
	position: relative;
	.custom-video__poster {
		@media (min-width: $screen-sm) {
			position: absolute;
			top: 0;
			left: 0;
		}
	}
}

// The party - Polaroids
.polaroids {
	margin-left: -10px;
	margin-right: -10px;
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	@media (min-width: $screen-sm) {
		perspective: 1000px;
		transform-style: preserve-3d;
	}
	.polaroid {
		width: 100%;
		padding: 10px;
		@media (min-width: $screen-sm) {
			width: 33.3%;
			float: left;
		}		
		@media (min-width: $screen-md) {
			width: 25%;
		}	
		@media (min-width: $screen-lg) {
			width: 20%;
		}
		.polaroid__inner {
			box-shadow: 0px 0px 4px 2px rgba(238,238,238,1);
			padding: 10px;
			@media (min-width: $screen-sm) {
				// transition: 0.6s;
				// transform-style: preserve-3d;
				position: relative;
				padding: 0;
				box-shadow: none;
				height: 100%;
			}
			.polaroid__title {
				text-transform: uppercase;
				margin: 0;
				padding-left: $grid-gutter-width / 2;
				padding-right: $grid-gutter-width;
				font-size: 12px;
				font-weight: $bold;
				display: flex;
				align-items: center;
				@media (min-width: $screen-sm) {
					text-align: center;
					justify-content: center;
					padding-left: 0;
					padding-right: 0;
					padding-top: 10px;
					flex-wrap: wrap;
				}
				.polaroid__name {
					margin-bottom: 0;
					width: 100%;
					line-height: 1;
					font-size: $font-size-large;
					padding-top: 10px;
				}
				.polaroid__role {
					margin-bottom: 0;
					width: 100%;
					font-weight: $normal;
				}
			}
			.polaroid__inner-front, .polaroid__inner-back {
				width: 100%;
				@media (min-width: $screen-sm) {
					backface-visibility: hidden;
					transition: 0.6s;
					transform-style: preserve-3d;
					position: absolute;
					top: 0;
					left: 0;
					box-shadow: 0px 0px 4px 2px rgba(238,238,238,1);
					padding: 10px;
					height: 100%;
					background-color: #fff;
				}
			}
			.polaroid__inner-front {
				position: relative;
				width: 100%;
				display: flex;
				@media (min-width: $screen-sm) {
					z-index: 2;
					transform: rotateY(0deg);
					display: block;
				}
				&:after {
					content: "\64";
					font-family: "mmtp";
					font-size: 30px;
					position: absolute;
					top: 0;
					right: 0;
					color: $brand-primary-dark;
					top: 50%;
					transform: translateY(-50%);
					@media (min-width: $screen-sm) {
						display: none;
					}
				}
				.polaroid__img {
					min-width: 80px;
					max-width: 80px;
					@media (min-width: $screen-sm) {
						max-width: 100%;
						width: initial;
					}
				}
				.polaroid__title {
					@media (min-width: $screen-sm) {
						height: 45px;
						line-height: 1.4;
					}
				}
			}
			.polaroid__inner-back {
				display: none;
				padding-left: 95px;
				padding-right: $grid-gutter-width / 2;
				@media (min-width: $screen-sm) {
					display: block;
					transform: rotateY(-180deg);
					padding: 10px;
					overflow-y: auto;
				}
				.polaroid__title {
					display: none;
					padding-top: 35px;
					font-size: $font-size-medium;
					@media (min-width: $screen-sm) {
						display: block;
						padding-bottom: 10px;
					}
				}
				.icon-flip {
					position: absolute;
					top: 10px;
					right: 10px;
					width: 30px;
					height: 30px;
				}
				p {
					font-size: $font-size-medium;
				}
			}
		}
		&.active {
			.polaroid__inner {
				.polaroid__inner-front {
					@media (min-width: $screen-sm) {
						transform: rotateY(180deg);
					}
					&:after {
						content: "\68";
						@media (min-width: $screen-sm) {
							display: none;
						}
					}
				}
				.polaroid__inner-back {
					display: block;
					&.overflow {
						z-index: 10;
					}
					@media (min-width: $screen-sm) {
						transform: rotateY(0deg);
					}
					.icon-flip {
						display: none;
						@media (min-width: $screen-sm) {
							display: block;
						}
					}
				}
			}
		}
	}
}

// Team - Polaroids
.polaroids--team {
	.polaroid {
		width: 50%;
		@media (min-width: $screen-xs) {
			width: 33.3%;
			float: left;
		}		
		@media (min-width: $screen-md) {
			width: 25%;
		}	
		@media (min-width: $screen-lg) {
			width: 20%;
		}
		.polaroid__inner {
			height: initial;
			.polaroid__inner-front {
				display: block;
				height: initial;
				z-index: 5;
				&:after {
					display: none;
				}
				.polaroid__title {
					text-align: center;
					padding: 10px 0 0 0;
					height: 70px;
					display: block;
					padding: 0;
					display: flex;
					align-items: center;
					justify-content: center;
					.polaroid__center {
						p {
							margin-bottom: 0;
						}
						.polaroid__name {
							font-size: $font-size-large;
							line-height: 1;
							margin-bottom: 2px;
							@media (min-width: $screen-sm) {
								font-size: $font-size-large * 1.2;
							}						
						}
						.polaroid__role {
							line-height: 1.2;
						}
					}
				}
			}
		}
		.polaroid__actor {
			padding-top: 10px;
			text-align: center;
			p {
				margin-bottom: 0;
			}
		}
	}
}

// Abba story - Polaroids
.polaroids--a-story {
	perspective: 1000px;
	transform-style: preserve-3d;
	.polaroid {
		width: 100%;
		float: left;
		@media (min-width: $screen-xs) {
			width: 50%;
		}
		@media (min-width: $screen-sm) {
			float: left;
		}		
		@media (min-width: $screen-md) {
			width: 25%;
			float: left;
		}
		.polaroid__inner {
			padding: 0;
			position: relative;
			height: 100%;
			box-shadow: none;
			.polaroid__inner-front, .polaroid__inner-back {
				transition: 0.6s;
				transform-style: preserve-3d;
				backface-visibility: hidden;
			}
			.polaroid__inner-front {
				padding: 0;
				display: block;
				transform: rotateY(0deg);
				background-color: $brand-primary;
				&:after {
					display: none;
				}
				.polaroid__img {
					max-width: 100%;
				}
				.polaroid__title {
					padding: $grid-gutter-width / 2;
					background-color: $brand-primary;
					color: #fff;
					height: 60px;
					font-weight: $normal;
					text-transform: none;
					letter-spacing: .5px;
					justify-content: center;
				}
			}
			.polaroid__inner-back {
				background-color: $brand-primary;
				color: #fff;
				text-align: center;
				transform: rotateY(-180deg);
				padding: 15px;
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				overflow: hidden;
				.polaroid__title {
					padding-top: 0;
					padding-bottom: 10px;
					letter-spacing: 1px;
					display: block;
					padding-left: 0;
					padding-right: 0;
					&.polaroid__title--blue {
						color: #81b5f0;
						padding-bottom: 0;
						padding-top: 3px;
					}				
				}
				p {
					font-size: $font-size-medium;
				}
				a {
					color: #fff;
				}
			}
		}
		&.active {
			.polaroid__inner {
				.polaroid__inner-front {
					transform: rotateY(180deg);
				}
				.polaroid__inner-back {
					transform: rotateY(0deg);
					&.overflow {
						overflow-y: auto;
					}
				}
			}
		}
	}	
}


// IE9 and below show polaroid text on click support
.lt-ie10 {
	.polaroids {
		.polaroid {
			&.active {
				.polaroid__inner {
					.polaroid__inner-front {
						transform: none;
						@media (min-width: $screen-sm) {
							visibility: hidden;
						}
					}
					.polaroid__inner-back {
						display: block;
						position: absolute;
						transform: none;
					}
				}
			}
		}
	}
}

// Custom grid
.c-grid {
	display: flex;
	flex-wrap: wrap;
	margin-left: -5px;
	margin-right: -5px;
	font-size: 0;
	.c-grid__item {
		padding: 5px;
		width: 50%;
		display: inline-block; // IE9 support
		display: flex;
		align-items: stretch;
		height: 230px;
		@media (min-width: $screen-xs) {
			width: 33.3%;
		}		
		@media (min-width: $screen-md) {
			width: 25%;
		}		
		@media (min-width: $screen-lg) {
			width: 20%;
			height: 260px;
		}
		.c-grid__link {
			display: block;
			width: 100%;
			background-size: cover;
			background-position: center;
		}
		.c-grid__content {
			box-shadow: 0px 0px 4px 2px rgba(238,238,238,1);
			padding: $grid-gutter-width / 2;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-wrap: wrap;
			width: 100%;
			min-height: 100%; //IE9 support
			text-align: center;
			h3 {
				font-size: $font-size-base;
				margin: 0;
			}
			p {
				font-size: $font-size-base;
				margin-top: 5px;
			}
			.btn {
				font-size: $font-size-medium;
				padding: 20px 15px;
				@media (min-width: $screen-lg) {
					padding: 20px 30px;
					font-size: $font-size-base;
				}
			}
		}
	}
}

/* ==========================================================================
8. Specifics
========================================================================== */

/* Landing page
========================================================================== */

.l-p {
	min-height: 100vh;
	background-size: cover;
	background-position: center top;
	color: #fff;
	padding-top: $grid-gutter-width;
	position: relative;
	@media (min-width: $screen-sm) {
		padding-top: $grid-gutter-width * 2;
	}
	// background-image: url(../images/backgrounds/bg-landing.png);
	.l-p__intro {
		p {
			text-align: center;
			font-size: $font-size-large;
			line-height: 1.3;
		}
	}
	.l-p__logo {
		max-width: 250px;
		@media (min-width: $screen-lg) {
			max-width: 350px;
		}
	}
	.custom-video {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
	}
	.alert {
		max-width: initial;
		width: 100%;
	}
}

// Party selection
.p-s {
	padding-top: 10px;
	@media (min-width: 321px) {
		padding-top: $grid-gutter-width * 2;
	}
	@media (min-width: $screen-lg) {
		padding-bottom: 40px;
	}
	.p-s__link {
		padding: 5px;
		padding-right: 120px;
		display: flex;
		align-items: center;
		background-color: transparent;
		color: #fff;
		text-decoration: none;
		text-transform: uppercase;
		position: relative;
		transition: all 0.5s ease;
		border: 2px solid #fff;
		font-size: $font-size-medium;
		margin-bottom: $grid-gutter-width / 2;
		.p-s__text {
			letter-spacing: 1px;
		}
		img {
			margin-right: $grid-gutter-width / 2;
			width: 60px;
		}
		span {
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			padding-top: $grid-gutter-width / 2;
			padding-bottom: $grid-gutter-width / 2;			
			padding-left: $grid-gutter-width;
			padding-right: $grid-gutter-width * 1.4;
			display: flex;
			align-items: center;
			&:after {
				content: '\67';
				font-family: 'mmtp';
				font-size: 20px;
				text-transform: none;
				position: absolute;
				right: 10px;
				top: 50%;
				transform: translateY(-50%);
			}
		}
		&:hover {
			background-color: #fff;
			color: $brand-primary;
			cursor: pointer;
			span {
				transition: all 0.5s ease;
				color: $brand-primary-dark;
			}
		}
		&.p-s__link--suggested {
			background-color: $brand-primary;
			border: none;
			font-size: $font-size-medium;
			@media (min-width: $screen-sm) {
				font-size: $font-size-base;
			}
			.p-s__text { 
				color: #fff;
			}
			span {
				background-color: $brand-primary-dark;
			}
			img {
				width: 90px;
			}
			&:hover {
				background-color: $brand-primary-dark;
				span {
					transition: all 0.5s ease;
					background-color: #fff;
					color: $brand-primary-dark;			
				}
			}
		}
	}
	h2 {
		font-weight: $normal;
		font-size: $font-size-h3;
	}	
	h3 {
		font-weight: $normal;
		font-size: $font-size-h4;
	}
	hr {
		border-top: 2px solid #fff;
	}
}

/* Home content
========================================================================== */

.h-c {
	width: 100%;
	min-height: 100vh;
	min-height: calc(100vh - 225px); 
	background-size: cover;
	background-position: center -50px;
	background-image: url(../images/backgrounds/bg-home-xs.jpg);
	position: relative;
	background-repeat: no-repeat;
	&.gb {
		background-image: url(../images/backgrounds/bg-ldn-header-sm-beige.jpg)!important;
			background-position: center -15px!important; 
		}
		/* Landscape */
		@media only screen 
		and (min-device-width: 320px) 
		and (max-device-width: 736px)
		and (orientation: landscape) {
			min-height: calc(200vh - #{$nav-height-xs});
		}
		@media (min-width: $screen-xs) {
			background-image: url(../images/backgrounds/bg-home-xs-tablet.jpg); 
			&.gb {
				background-image: url(../images/backgrounds/bg-ldn-header-sm-beige.jpg)!important; 
			}
		}	
		@media (min-width: $screen-sm) {
			min-height: calc(100vh - 180px);
		// min-height: 470px;
		background-image: url(../images/backgrounds/bg-home-sm.jpg); 
		background-position: center top;
		&.gb {
			background-image: url(../images/backgrounds/bg-ldn-header-md-beige.jpg)!important; 
		}
	}
	@media (min-width: $screen-sm) and (max-height: 470px)  {
		background-position: center -120px;
	}
	@media (min-width: $screen-md) {
		background-image: url(../images/backgrounds/bg-home-md.jpg);
		// min-height: 570px;
		&.gb {
			background-image: url(../images/backgrounds/bg-ldn-header-md-beige.jpg)!important; 
		}
	}
	@media (min-width: $screen-md) and (max-height: 570px)  {
		background-position: center -150px;
	}
	@media (min-width: $screen-lg) {
		background-image: url(../images/backgrounds/bg-home-xl.jpg);
		&.gb {
			background-image: url(../images/backgrounds/bg-ldn-header-xl.jpg)!important; 
		}
		// min-height: 670px;
	}
	@media (min-width: $screen-lg) and (max-height: 670px)  {
		background-position: center -150px;
	}

	// @media (min-width: $screen-xl) {
	// 	background-image: url(../images/backgrounds/bg-home-xl.jpg);
	// 	&.gb {
	// 		background-image: url(../images/backgrounds/bg-ldn-header-xl.jpg)!important; 
	// 	}
	// }
	// @media (min-width: $screen-xl) and (max-height: 770px)  {
	// 	background-position: center -150px;
	// }
	.h-c__watch-trailer {
		position: absolute;
		right: 50%;
		transform: translateX(50%);
		bottom: 190px;
		font-weight: $bold;
		padding: 10px 30px;
		@media (min-width: 321px) {
			bottom: 240px;
		}		
		@media (min-width: $screen-xs) {
			bottom: 180px;
		}		
		@media (min-width: $screen-sm) {
			bottom: 15%;
			padding: 15px 30px;
		}
	}	
	.h-c__links {
		position: absolute;
		right: 50%;
		transform: translateX(50%);
		bottom: 70px;
		display: flex;
		align-items: stretch;
		width: 100%;
		@media (min-width: $screen-sm) {
			flex-wrap: wrap;
			// bottom: $grid-gutter-width;
		}		
		@media (min-width: $screen-md) {
			width: 99%;
		}		
		@media (min-width: $screen-lg) {
			width: 90%;
		}
		.h-c__link {
			display: inline-block; // IE9 support
			width: 32%; // IE9 support
			padding: 5px;
			white-space: normal;
			flex-basis: initial;
			justify-content: center;
			display: flex;
			align-items: center;
			text-align: center;
			flex-grow: 1;
			margin-left: 2px;
			margin-right: 2px;
			font-size: $font-size-small;
			letter-spacing: 0;
			&:first-child {
				margin-left: 4px;
			}
			&:last-child {
				margin-right: 4px;
			}
			@media (min-width: $screen-sm) {
				flex-basis: 0;
				padding: 15px;
				letter-spacing: 1px;
			}
			@media (min-width: $screen-md) {
				font-size: $font-size-medium;
				padding: 15px 10px;
			}
		}
	}
	.alerts {
		position: absolute;
		top: 0;
		left: $grid-gutter-width / 2;
		width: calc(100% - #{$grid-gutter-width});
		@media (min-width: 815px) {
			left: $grid-gutter-width;
		}
	}
}

// Location signup
.page-template-template-signup,
.page-template-template-home-new {
	.h-c {
		background-image: url(../images/backgrounds/bg-header-sm-2.jpg);
		background-size: 100%;
		background-color: #fadbc7;
		padding-top: 70%;
		background-position: center -110px;
		@media (min-width: $screen-xs) {
			background-position: center -100px;
		}
		@media (min-width: $screen-sm) {
			padding-top: 40%;
			background-image: url(../images/backgrounds/bg-header-md-2.jpg);
			background-color: #000;
			background-size: 125%;
			background-position: center -70px;
		}
		@media (min-width: $screen-md) {
			background-size: 100%;
			background-position: center top;
			background-image: url(../images/backgrounds/bg-header-xl-2.jpg);
		}	
		h1 {
			font-size: 20px;
			line-height: 26px;
		}
		h3 {
			margin-bottom: -10px;
			text-transform: inherit;
			font-weight: 100;
			font-size: 2.8rem;
		}
		.h-c__watch-trailer {
			position: relative;
			margin: 10px auto;
			transform: none;
			right: auto;
			bottom: auto;
			// left: calc(50% - 99px);

			@media (min-width: $screen-lg) {
				display: inherit;
				// margin: 20px 60px;
				left: 0;
			}
		}
	}
}

// .page-id-654 .h-c__links {
// 	display: none;
// }

/* Venue
========================================================================== */

.g-map {
	// iframe {
	// 	float: left;
	// 	border: 4px solid $brand-primary;
	// }
	// ACF maps
	.acf-map {
		width: 100%;
		height: 300px;
		border: 4px solid $brand-primary;
		@media (min-width: $screen-sm) {
			height: 550px;
		}
	}

	/* fixes potential theme css conflict */
	.acf-map img {
		max-width: inherit !important;
	}
	.btn {
		float: right;
		width: 100%;
		font-weight: $bold;
		padding: 15px 25px;
		@media (min-width: $screen-md) {
			width: auto;
		}
	}
}

.venue-accordion {
	.panel {
		border: 1px solid #004390;
		margin-bottom: 10px;
		padding: 10px;
		border-radius: 0;
		.panel-heading {
			background: transparent;
			border: none;
			color: #004390;
			padding: 0;
			h4.panel-title {
				a {
					text-decoration: none;
					display: block;
					position: relative;
					font-weight: normal;
					text-transform: none;
					padding: 10px 15px;
					&:after {
						content: '';
						position: absolute;
						width: 26px;
						height: 20px;
						background: url(../images/arrow.png) no-repeat 0 0;
						background-size: contain;
						right: 10px;
						top: 50%;
						transform: translateY(-50%);
					}
					&.collapsed:after {
						transform: translateY(-50%) rotate(180deg);
					}
					img {
						width: 30px;
						margin-right: 20px;
					}
				}
			}
		}
		.panel-collapse > .panel-body {
			border: none;
			padding-bottom: 0;
			.row {
				margin-bottom: 10px;
			}
		}
	}
}

#venue-carousel {
	margin-bottom: 94px;
	border: 4px solid #00264f;

	@media (min-width: $screen-md) {
		margin-top: 45px;
	}

	// @media (min-width: $screen-lg) {
	// 	margin-top: 196px;
	// }


	ol.carousel-indicators {
		text-align:left;
		left: 15px;
		bottom: -45px;
		margin: 0;
		padding: 0;
		li {
			border-radius: 0;
			width: 10px;
			height: 10px;
			background: #CFD2D9;
			border: none;
			margin: 0 0 0 10px;
			padding: 0;
			&.active {
				background: #0E2040;
			}
		}
	}
	.carousel-control {
		background-image: none;
		width: 55px;
		height: 55px;
		line-height: 55px;
		top: 100%;
		background: #00264f;
		opacity: 1;
		font-size: 30px;
		&.left {
			left: auto;
			right: 52px;
		}
		&.right {
			right: -4px;
		}
		span {
			width: 55px;
			height: 55px;
			line-height: 55px;
		}
	}
}

/* Cast
========================================================================== */

.credit-title {
	font-weight: $bold;
	text-align: right;
	font-size: $font-size-small;
	@media (min-width: $screen-xs) {
		font-size: $font-size-medium;
	}	
	@media (min-width: $screen-sm) {
		font-size: $font-size-base;
	}
}

.credit-text {
	p {
		margin-bottom: 5px;
		font-size: $font-size-small;
		@media (min-width: $screen-xs) {
			font-size: $font-size-medium;
		}	
		@media (min-width: $screen-sm) {
			font-size: $font-size-base;
		}
	}
}

/* Abba story
========================================================================== */

.a-s-image {
	position: relative;
	padding-bottom: $grid-gutter-width / 2;
	.a-s-image__fade {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		&.animated {
			transition-property: opacity;
			transition-duration: 1s;
			transition-timing-function: ease;
			opacity: 1;
			@media (min-width: $screen-sm) {
				&:hover {
					transition-duration: .4s;
					opacity: 0;
				}				
			}
			&.active {
				transition-duration: .4s;
				opacity: 0;
			}
		}
	}
}

.a-s-name {
	text-align: center;
	text-transform: uppercase;
	font-weight: $bold;
}

/* Social
========================================================================== */

.s-grid__item {
	width: 50%;
	height: 200px;
	background-size: cover;
	background-position: center;
	display: flex;
	flex-wrap: wrap;
	overflow-y: hidden;
	@media (min-width: $screen-xs) {
		height: 220px;
	}
	@media (min-width: $screen-sm) {
		width: calc(100% / 4);
		height: 240px;
	}	
	@media (min-width: $screen-md) {
		width: calc(100% / 5);
	}	
	@media (min-width: $screen-lg) {
		width: calc(100% / 6);
		height: 260px;
	}
	.s-grid__inner-content {
		overflow-y: auto;
		opacity: 0;
		transition: all 0.3s ease;
		width: 100%;
		height: 100%;
		.s-grid__content {
			min-height: 100%;
			background-color: rgba($brand-primary-dark, 0.5);
			color: #fff;
			padding: $grid-gutter-width;
		}
	}
	@media (min-width: $screen-sm) {
		&:hover {
			.s-grid__inner-content {
				opacity: 1;
			}
		}
	}
	&.active {
		.s-grid__inner-content {
			opacity: 1;
		}
	}
	&.s-grid__item--large {
		width: 100%;
		height: 400px;
		padding: $grid-gutter-width;
		@media (min-width: $screen-xs) {
			height: 440px;
		}
		@media (min-width: $screen-sm) {
			width: 50%;
			height: 480px;
		}		
		@media (min-width: $screen-md) {
			width: calc(100% / 2.5);
		}		
		@media (min-width: $screen-lg) {
			height: 520px;
			width: calc(100% / 3);
		}
	}	
	&.s-grid__item--fb-blue {
		background-color: #002c5a;
		color: #fff;
		padding: $grid-gutter-width / 2;
		@media (min-width: $screen-sm) {
			padding: $grid-gutter-width;
		}
		.s-grid__inner-content {
			padding: 0;
			opacity: 1;
		}
	}	
	&.s-grid__item--fb-white {
		background-color: #fff;
		color: $brand-primary-light;
		padding: $grid-gutter-width / 2;
		@media (min-width: $screen-sm) {
			padding: $grid-gutter-width;
		}
		.s-grid__inner-content {
			padding: 0;
			opacity: 1;
		}
	}
	p {
		margin-bottom: $grid-gutter-width / 4;
		line-height: 1.3;
		width: 100%;
		font-size: $font-size-small;
		@media (min-width: $screen-sm) {
			font-size: $font-size-medium;
		}
	}
	.icon {
		font-size: $font-size-large * 1.5;
	}
}

/* Press
========================================================================== */

// Press collapse
.btn-p-c {
	margin-top: $grid-gutter-width;
	background-color: transparent;
	border: none;
	width: 100%;
	text-align: center;
	text-transform: uppercase;
	.btn-text {
		position: relative;
		padding-left: $grid-gutter-width;
		&:after {
			font-family: 'mmtp';
			content: '\63';
			position: absolute;
			left: 0;
			text-transform: none;
		}
	}
	&.collapsed {
		margin-top: 0;	
		.btn-text {
			&:after {
				content: '\64';
			}
		}
	}
}

// Press release / Latest news
.press {
	h2 {
		@media (min-width: $screen-sm) {
			padding-bottom: $grid-gutter-width / 2;
		}
	}
}

.pr-wrap {
	padding: $grid-gutter-width / 2;
	border-radius: 3px;
	box-shadow: 0px 0px 4px 2px rgba(238,238,238,1);
	background-color: #fff;
	margin-bottom: $grid-gutter-width / 2;
}

.pr {
	font-size: 0; // IE9 support
	display: flex;
	align-items: center;
	padding-bottom: $padding-large-vertical * 2;
	&:last-child {
		padding-bottom: 0;
	}
	.pr__title {
		width: 50%;
		display: inline-block;
		padding-right: 5%;
		@media (min-width: $screen-sm) {
			width: 60%;
		}		
		@media (min-width: $screen-lg) {
			width: 70%;
		}
		h3 {
			margin: 0;
			font-size: $font-size-base;
			letter-spacing: 0;
		}
		p {
			margin-bottom: 0;
			font-size: $font-size-base;
			text-transform: uppercase;
		}
	}
	.pr__btn {
		width: 50%;
		display: inline-block;
		@media (min-width: $screen-sm) {
			width: 40%;
		}				
		@media (min-width: $screen-lg) {
			width: 30%;
		}
		.btn {
			padding: 20px 15px;
			display: block;
			width: 100%;
			font-size: $font-size-medium;
			@media (min-width: $screen-sm) {
				padding: 20px 30px;
			}
			@media (min-width: $screen-lg) {
				font-size: $font-size-base;
			}
		}
	}
	&.pr--video {
		.pr__title {
			width: 40%;
			@media (min-width: $screen-md) {
				width: 45%;
			}
			@media (min-width: $screen-lg) {
				width: 55%;
			}
		}
		.pr__btn {
			width: 60%;
			display: flex;
			@media (min-width: $screen-md) {
				width: 55%;
			}
			@media (min-width: $screen-lg) {
				width: 45%;
			}
			.btn-youtube {
				margin-left: 5px;
				padding: 5px 15px;
				font-size: 25px;
				line-height: 1;
				flex-basis: 0;
				display: flex;
				align-items: center;
				@media (min-width: $screen-sm) {
					font-size: 35px;
				}
			}
		}
	}
}

.lt-ie10 {
	.pr--video {
		.pr__btn {
			font-size: 0;
			.btn {
				width: 74%;
				display: inline-block;
				height: 100%;
			}
			.btn-youtube {
				width: 25%;
				margin-left: 1%;
			}
		}
	}
}

/* Signup
========================================================================== */

.form-container {
	margin: 0 auto;
	color: #fff;
	@media (min-width: $screen-sm) {
		width: 70%;
	}	
	@media (min-width: $screen-md) {
		width: 52%;
	}	
	@media (min-width: $screen-lg) {
		width: 50%;
	}
	.mmtp-logo {
		padding-top: 35%;
		padding-bottom: 0;
		padding-left: $grid-gutter-width;
		padding-right: $grid-gutter-width;
	}
	.blue-radial {
		background: rgba(11,94,135,1);
		background: radial-gradient(ellipse at center, rgba(11,94,135,1) 0%, rgba(22,51,85,1) 100%);
		padding: $grid-gutter-width;
		p {
			color: #fff;
		}
		a {
			color: #fff;
			text-decoration: underline;
			&.btn {
				text-decoration: none;
			}
		}
		.btn {
			min-width: 200px;
		}
	}
	form {
		margin: 0 auto;
		@media (min-width: $screen-sm) {
			width: 65%;
		}
	}
}

.mc-terms {
	font-size: 14px;
	@media (min-width: $screen-sm) {
		font-size: 16px;
	}
}


.cookies {
	position: fixed;
	right: 0;
	left: 0;
	bottom: 10px;
	max-width: 1240px!important;
	width: 100%;
	margin: 0 10px;
	z-index: 1002;
	display: none;
	margin: 0 auto;
	.cookie-content {
		border: 1px solid #fff;
		background-color: rgba(0, 0, 0, .9);
		padding: 20px;
		@media (min-width: $screen-sm) {
			align-items: center;
			justify-content: space-between;
			display: flex;
		}
	}
	p {
		text-align: left;
		margin: 0;
		color: white;
		font-size: 12px;
		@media (min-width: $screen-sm) {
			font-size: 13px;
		}
		a {
			text-decoration: none;
		}
	}
	.cookie-copy {
		@media (min-width: $screen-sm) {
			width: 80%;
		}
	}
	.cookie-close {
		@media (min-width: $screen-sm) {
			width: 20%;
		}
	}
	.btn {
		margin: 10px 0 0 0;
		color: white;
		padding: 5px 15px;
		border: none;
		font-size: 12px;
		text-align: center;
		@media (min-width: $screen-sm) {
			width: auto;
			margin: 10px 0 10px 10px;
		}
	}
}


/* ==========================================================================
9. Partners Components
========================================================================== */


.partners-content {
	
	.partners-text  {
		// padding-right: 50px;
		padding: 0;
	}	

	.partners-img {
		margin-top: -15px;

		img {
			// box-shadow: 2px 7px 9px 0px rgba(189,189,189,1);
			margin-top: 20px;
		}



		@media(max-width:$screen-md){

			margin-top: 50px;	
			
		}


	}
	

}



.partners-content {

	margin-top:50px;

	.partners-img {

		@media(min-width: $screen-md){

			margin-top: 0px;


		}

	}

}


.partners-boxes {

	h3 {
		font-size: 1.5rem;

		@media(min-width: 992px){
		text-align: left;
	  }
	}
	
}

.box-aexpress,.box-Estandard,.box-Hilton {
	display:block;
	text-align: center;
	padding: 5px;

	@media(min-width: 1028px){
		display:inline-block;
		margin-left: 0px;
		text-align: left;
		padding: 20px 0;
	}

}

.other-partners {
	margin-top: 50px;
}
.other-partners{

	h3 {	
		font-size: 18px;
	}
	img {
		margin-left: 10px;
		margin-top: 30px;
	}
}

.img-hover-efect img:hover {
	transform: scale(1.1);
}
.box-aexpress img,.box-Estandard img,.box-Hilton img{
	transition: .3s ease-in-out all;
}
.box-aexpress img:hover,.box-Estandard img:hover,.box-Hilton img:hover{
	transform: scale(1.1);
	box-shadow: 1px 3px 9px 0px rgba(189,189,189,1);
}	


.other-partners a {
	display: block;
	text-align: center;
	margin-top: 30px;

	@media(min-width: $screen-sm){
		display: inline-block;
	}
	
}

	//prematurely breaking bootstrap at 1199px


	.partners-content .col-md-5 {
		width: 100%;

		@media(min-width: 1199px){
			width: 41.66666667%;
		}
	}

	.partners-content .col-md-7 {

		width: 100%;
		
		@media(min-width: 1199px){
			width: 58.33333333%;
		}
	}	


	@media(max-width: $screen-sm){
		.other-partners a {
			display: block;
			text-align: center;
			margin-top: 30px;
		}	

		.other-partners {
			text-align: center;
			padding-top: 50px;
			padding-bottom: 50px;
		}
	}







/* ==========================================================================
10. Gallery Template
========================================================================== */


#lg-share,#lg-actual-size,#lg-zoom-out,#lg-zoom-in,#lg-download {
	display:none;
}
.thum-images {
	background-size:cover; background-position:center top;padding-top:250px;margin-top: 25px;
}
.lg-thumb-outer {
	display: none;
}
.thum-images {
	position: relative;
	.play {
		width: 80px;
		height: 80px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 10;
		border-radius: 50%;
		border: 10px solid #fff;
		&:after {
			content: '';
			position: absolute;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 14px 0 14px 25px;
			border-color: transparent transparent transparent #fff;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}	
	}
}

/* ==========================================================================
10. Tickets Template
========================================================================== */


.tickets-page {
	.tickets-content {
		iframe {
			width: 1px; 
			min-width: 100%;
		}
	}
}

// scroll 
.overflow::-webkit-scrollbar {
	-webkit-appearance: none;
	width: 10px;
}

.overflow::-webkit-scrollbar-thumb {
	border-radius: 5px;
	background-color: rgba(0, 0, 0, .5);
	box-shadow: 0 0 1px rgba(255, 255, 255, .5);
	-webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}